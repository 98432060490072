import './App.scss';
import Api from './service/api';
import Favicon from 'react-favicon';
import React, { Suspense } from 'react';
import { useEffect, useState } from 'react';
import DomainContext from './app-global/DomianContext';
import { HashRouter, Route, Switch } from 'react-router-dom';
import TranslateContext from './app-global/TranslateService';

const api = new Api();

const Login = React.lazy(() => import('./app-auth/login'));
const Payment = React.lazy(() => import('./Pages/Payment'));
const Failure = React.lazy(() => import('./Pages/Components/Failure'));
const Success = React.lazy(() => import('./Pages/Components/Success'));
const CitConSucess = React.lazy(() => import('./Pages/Components/CitConSucess'))
const CitConFailure = React.lazy(() => import('./Pages/Components/CitConFailure'));
const TossPayProcess = React.lazy(() => import('./Pages/Components/TossPayPending'));

function App() {

  const [lang, setLang] = useState();
  const [domainDetails, setDomainDetails] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    setLang(params.get('lang'));
    sessionStorage.setItem('skipAuth', params.get('skipAuth'));
    sessionStorage.removeItem('errorMessage');
    if ((params.get('status') === 'FAILED') && params.get('statusMessage')) {
      window.location.href = window.location.origin + '/#/failure';
    } else if ((params.get('status') === 'SUCCESS') && params.get('statusMessage')) {
      window.location.href = window.location.origin + '/#/success';
    }
    getDomainInfo();
    // eslint-disable-next-line
  }, [])

  const getDomainInfo = async () => {
    const url = 'auth/domain';
    let result = await api.mainRestCallService(url, 'GET');
    if (result?.data) {
      var data = JSON.parse(JSON.stringify(result.data));
      data = JSON.parse(data);
      setDomainDetails(data);
      document.title = data.title.concat(' | ').concat('Checkout')
      const linkElement = document.createElement("link");
      linkElement.setAttribute("rel", "stylesheet");
      linkElement.setAttribute("type", "text/css");
      linkElement.setAttribute("href", data.cdnDomain + data.css);
      document.head.appendChild(linkElement);
    }
  }

  return (
    <div id="domainClass" className="default">
      {
        (domainDetails) ?
          <DomainContext.Provider value={domainDetails}>
            <Favicon url={domainDetails.cdnDomain + domainDetails.favIcon} />
            <HashRouter>
              <TranslateContext.Provider value={{ lang, setLang }}>
                <Suspense fallback={lang && <div>{lang['loadingTxt']}</div>}>
                  <Switch>
                    <Route path="/redirect" exact component={Login} />
                    <Route path="/redirect/:order_token" exact component={Login} />
                    <Route path='/guest-checkout'><Payment /></Route>
                    <Route path='/checkout' component={Payment} />
                    <Route path='/success' component={Success} />
                    <Route path='/failure' component={Failure} />
                    <Route path="/citcon/failure/:token" component={CitConFailure} />
                    <Route path="/citcon/success/:token" component={CitConSucess} />
                    <Route path='/tosspay-payment-processing' component={TossPayProcess} />
                  </Switch>
                </Suspense>
              </TranslateContext.Provider>
            </HashRouter>
          </DomainContext.Provider>
          : null
      }
    </div>
  );
}

export default App;
